import "./SearchCar.css";
import React from "react";
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";

function SearchCar() {
	const [ activeTab, setActiveTab ] = useState(0);
	const [ currentDate, setCurrentDate ] = useState('');
	const [ currentTime, setCurrentTime ] = useState('');
	const [ period, setPeriod ] = useState('AM');
	const [ minDate, setMinDate ] = useState('');
	const [ maxDate, setMaxDate ] = useState('');


	useEffect(() => {
		//date
		const today = new Date();
		const year = today.getFullYear();
		const month = String(today.getMonth() + 1).padStart(2, '0');
		const day = String(today.getDate()).padStart(2, '0');
		const formattedDate = `${year}-${month}-${day}`;
		setCurrentDate(formattedDate);

		// Get date three months from today
		const threeMonthsLater = new Date();
		threeMonthsLater.setMonth(today.getMonth() + 1);
		const maxYear = threeMonthsLater.getFullYear();
		const maxMonth = String(threeMonthsLater.getMonth() + 1).padStart(2, '0');
		const maxDay = String(threeMonthsLater.getDate()).padStart(2, '0');
		const formattedMaxDate = `${maxYear}-${maxMonth}-${maxDay}`;

		// Set the min and max date values
		setMinDate(formattedDate);
		setMaxDate(formattedMaxDate);

		// time
		let hours = today.getHours();
		const minutes = String(today.getMinutes()).padStart(2, '0');
		const period = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12 || 12;
		const formattedTime = `${String(hours).padStart(2, '0')}:${minutes}`;

		setCurrentDate(formattedDate);
		setCurrentTime(formattedTime);
		setPeriod(period);
	}, []);

	const handleTimeChange = (e) => {
		const time = e.target.value;
		let [ hours, minutes ] = time.split(':');
		hours = parseInt(hours, 10);

		if (period === 'PM' && hours < 12) {
			hours += 12;
		} else if (period === 'AM' && hours === 12) {
			hours = 0;
		}

		const newTime = `${String(hours).padStart(2, '0')}:${minutes}`;
		setCurrentTime(newTime);
	};


	const tabData = [
		{
			label: 'ONE WAY',
			content: (
				<form className="grid grid-cols-4 mt-6 gap-10">
					<div>
						<label htmlFor="from">FROM</label><br />
						<input className="cabDetailsInput" type="text" id="from" placeholder="start typing city eg-Indore" />
					</div>
					<div>
						<label htmlFor="to">TO</label><br />
						<input className="cabDetailsInput" type="text" id="to" placeholder="start typing city eg-Delhi" />
					</div>
					<div>
						<label htmlFor="pickUpDate">PICK UP DATE</label><br />
						<input className="cabDetailsInput" type="date" id="pickUpDate" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} min={minDate} max={maxDate} />
					</div>
					<div>
						<label htmlFor="pickUpTime">PICK UP TIME</label><br />
						<input className="cabDetailsInput" type="time" id="pickUpTime" value={currentTime} onChange={handleTimeChange} />
					</div>
				</form>
			),
		},
		{
			label: 'ROUND TRIP',
			content: (
				<form className="grid grid-cols-5 mt-6 gap-10">
					<div>
						<label htmlFor="from">FROM</label><br />
						<input className="cabDetailsInput" type="text" id="from" placeholder="start typing city eg-Indore" />
					</div>
					<div>
						<label htmlFor="to">TO</label><br />
						<input className="cabDetailsInput" type="text" id="to" placeholder="start typing city eg-Delhi" />
					</div>
					<div>
						<label htmlFor="pickUpDate">PICK UP DATE</label><br />
						<input className="cabDetailsInput" type="date" id="pickUpDate" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} min={minDate} max={maxDate} />
					</div>
					<div>
						<label htmlFor="returnDate">RETURN DATE</label><br />
						<input className="cabDetailsInput" type="date" id="pickUpDate" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} min={minDate} max={maxDate} />
					</div>
					<div>
						<label htmlFor="pickUpTime">PICK UP TIME</label><br />
						<input className="cabDetailsInput" type="time" id="pickUpTime" value={currentTime} onChange={handleTimeChange} />
					</div>
				</form>
			),
		},
		{
			label: 'ONE DAY BOOKING',
			content: (
				<div>
					<form className="grid grid-cols-3 mt-6 gap-10">
						<div>
							<label htmlFor="from">PICK UP LOCATION</label><br />
							<input className="cabDetailsInput" type="text" id="from" placeholder="start typing city eg-Indore" />
						</div>
						{/* <div>
							<label htmlFor="to">TO</label><br />
							<input className="cabDetailsInput" type="text" id="to" placeholder="start typing city eg-Delhi" />
						</div> */}
						<div>
							<label htmlFor="pickUpDate">PICK UP DATE</label><br />
							<input className="cabDetailsInput" type="date" id="pickUpDate" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} min={minDate} max={maxDate} />
						</div>
						<div>
							<label htmlFor="pickUpTime">PICK UP TIME</label><br />
							<input className="cabDetailsInput" type="time" id="pickUpTime" value={currentTime} onChange={handleTimeChange} />
						</div>
					</form>
				</div>
			),
		},
		{
			label: 'LOCAL/AIRPORT',
			content: (
				<form className="grid grid-cols-5 mt-6 gap-10">
					<div>
						<label htmlFor="trip">TRIP</label><br />
						{/* <input className="cabDetailsInput" type="text" id="trip" placeholder="start typing city eg-Indore" /> */}
						<select id="trip" className="tripOption" onChange={(e) => { }}>
							<option value="pickup">Pickup from Airport</option>
							<option value="drop">Drop to Airport</option>
						</select>
					</div>
					<div>
						<label htmlFor="to">PICKUP ADDRESS</label><br />
						<input className="cabDetailsInput" type="text" id="to" placeholder="start typing address" />
					</div>
					<div>
						<label htmlFor="to">DROP AIRPORT</label><br />
						<input className="cabDetailsInput" type="text" id="to" placeholder="start typing airport name or city" />
					</div>
					<div>
						<label htmlFor="pickUpDate">PICK UP DATE</label><br />
						<input className="cabDetailsInput" type="date" id="pickUpDate" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} min={minDate} max={maxDate} />
					</div>
					<div>
						<label htmlFor="pickUpTime">PICK UP TIME</label><br />
						<input className="cabDetailsInput" type="time" id="pickUpTime" value={currentTime} onChange={handleTimeChange} />
					</div>
				</form>
			),
		},
	];

	return (
		<section className="ExploreCab-section">
			<div className="search-car bg-Image flex justify-center pt-20"></div>
			<div className="CabDetails container w-full h-[200px] rounded-3xl px-8 py-4 relative -top-28">

				<ul className="cabOption flex justify-center">
					{tabData.map((tab, index) => (
						<li
							key={index}
							className={activeTab === index ? 'active' : ''}
							onClick={() => setActiveTab(index)}
						>
							{tab.label}
						</li>
					))}
				</ul>

				<div className="tab-content">{tabData[ activeTab ].content}</div>

				<Link to="./selectCar" className="m-auto flex justify-center">
					<button className="search-button">Search car</button>
				</Link>
			</div>
		</section >
	);
}

export default SearchCar;