import React from 'react'
import './contact.css';
import { IoLogoInstagram } from "react-icons/io5";
import { FiFacebook } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import { LuMail } from "react-icons/lu";


function Contact() {
	return (
		<>
			<section className='mt-10 bg-[#f6f6f6]'>
				<div className='container pt-8 pb-14 flex gap-10'>
					<div className='w-[50%]'>
						<p className='font-normal text-[36px]'>Get in touch</p>
						<div className='details mt-10'>
							<p>Call</p>
							<a href="tel:+91 9201010334" >+91 92010 10334</a>
						</div>
						<div className='details mt-6'>
							<p>email</p>
							<a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=mail@truetaxi.in" target='_blank' rel="noreferrer">mail@truetaxi.in</a>
						</div>
						<div className='details mt-6'>
							<p>social link</p>
							<div className='mt-2 flex items-center gap-6'>
								<a href="https://www.instagram.com/truetaxi.in?igsh=YjkxOHNjMjNoNnhs" target='_blank' rel="noreferrer"><IoLogoInstagram size={22} /></a>
								<a href="https://wa.me/919201010334" target='_blank' rel="noreferrer"><FaWhatsapp size={22} /></a>
								<a href="https://www.facebook.com/profile.php?id=61559204944761&mibextid=ZbWKwL" target='_blank' rel="noreferrer"><FiFacebook size={22} /></a>
								<a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=mail@truetaxi.in" target='_blank' rel="noreferrer"><LuMail size={22} /></a>
							</div>
						</div>
					</div>

					<div className='w-[50%]'>
						<form action="">
							<div className='inputField'>
								<p className=''>Name</p>
								<input type="text" name="" required />
							</div>
							<div className='inputField'>
								<p className=''>Email</p>
								<input type="email" name="" required />
							</div>
							<div className='inputField'>
								<p className=''>Phone</p>
								<input type="number" name="" required />
							</div>
							<div className='inputField'>
								<p className=''>Your message</p>
								<textarea name="" rows={3} required></textarea>
							</div>
							<button type='submit' className='mt-6 px-8 py-2 bg-[#e84f03] text-white text-lg'>Submit</button>
						</form>
					</div>
				</div>
			</section>
		</>
	)
}

export default Contact;