import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { RiInstagramFill } from "react-icons/ri";
import { IoIosMail } from "react-icons/io";

function Footer() {
	return (
		<>
			<footer className='bg-themeColor pt-5'>
				<div className='container'>
					<section className='px-32 flex justify-between'>
						<div className='footerLink'>
							<b className='font-semibold text-[18px] text-white underline underline-offset-2'>IMPORTANT LINK</b><br />
							<Link to={'/About'}><p className='mt-3'>About</p></Link><br />
							<Link to={'/SpecialEvents'}><p>Special Events</p></Link><br />
							<Link to={'/PrivacyPolicy'}><p>Privacy Policy</p></Link><br />
							<Link to={'/TermsAndConditions'}><p>Terms & Conditions</p></Link><br />
							<Link to={'/Refunds'}><p>Refunds</p></Link>
						</div>
						<div className='services'>
							<b className='font-semibold text-[18px] text-white underline underline-offset-2'>SERVICES</b><br />
							<p className='mt-3'>Airport Transfers</p>
							<p>Local Car</p>
							<p>City Tours</p>
							<p>Special Events</p>
							<p>Corporate Transportation</p>
						</div>
						<div className=''>
							<p className='font-semibold text-[18px] text-white underline underline-offset-2'>Contact Us</p>
							<Link to={'/MessageMe'}><p className='underline-animation text-white mt-3'>Message me</p></Link><br />
							<p className='text-slate-100'>Phone - <a href="tel:+91 9201010334" target='_blank' rel="noreferrer" className='font-thin tracking-wider'>+91 92010 10334</a></p>
							<p className='text-slate-100'>Mail - <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=mail@truetaxi.in" target='_blank' rel="noreferrer" className='font-thin tracking-wider'>mail@truetaxi.in</a>
							</p>
							<p className='text-slate-100'>Social Link</p>
							<div className='mt-1 flex items-center gap-6'>
								<a href="https://www.instagram.com/truetaxi.in?igsh=YjkxOHNjMjNoNnhs" target='_blank' rel="noreferrer"><RiInstagramFill size={20} fill='#d4dae2' /></a>
								<a href="https://wa.me/919201010334" target='_blank' rel="noreferrer"><IoLogoWhatsapp size={20} fill='#d4dae2' /></a>
								<a href="https://www.facebook.com/profile.php?id=61559204944761&mibextid=ZbWKwL" target='_blank' rel="noreferrer"><FaFacebookF size={20} fill='#d4dae2' /></a>
								<a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=mail@truetaxi.in" target='_blank' rel="noreferrer"><IoIosMail size={24} fill='#d4dae2' /></a>
							</div>
						</div>
					</section>
				</div >
				<div className='bg-[#025aa4] py-2 mt-6'>
					<div className='container flex justify-between px-5'>
						<p className='text-white font-[300] text-[17px] tracking-wide'>Copyright @ 2024 All Right Reserved By, TrueTaxi.</p>
						<p className='text-white font-thin text-lg tracking-wide	'>Developed By <a href="https://tausif40.github.io/Portfolio/" className='underline-animation !font-medium hover:text-[#d5d5ec] transition !text-[17px]' target='_blank' rel="noreferrer">Mohd. Tausif</a></p>
					</div>
				</div>
			</footer >
		</>
	)
}

export default Footer