import React from 'react'
import './CarOptions.css'

function TaxiOptions() {
	return (
		<>
			<main className='container mt-16'>
				<div className='flex'>
					<aside className='leftOption mr-4'>
						<p className='heading'>Car Option</p>
						<p className='mt-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni soluta doloribus facilis similique, provident voluptatem dolore sint odio dolor omnis amet fugit aut ipsa saepe numquam nisi aspernatur vel. Repudiandae!</p>

						<table className='mt-8 w-full'>
							<thead>
								<tr>
									<th>Vehicle Name</th>
									<th>Capacity</th>
									<th>Per Km Rate</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Sedan</td>
									<td>4 seater</td>
									<td>Rs. 9</td>
								</tr>
								<tr>
									<td>Hatchback</td>
									<td>4 seater</td>
									<td>Rs. 9</td>
								</tr>
								<tr>
									<td>SUV</td>
									<td>7 seater</td>
									<td>Rs. 12.5</td>
								</tr>
								<tr>
									<td>Assured Innova</td>
									<td>7 seater</td>
									<td>Rs. 13.5</td>
								</tr>
							</tbody>
						</table>
					</aside>
					<div className='w-2 bg-slate-700'></div>
					<aside className='rightOption bg-slate-200'>
						<p className='heading'>Best car service in India</p>
						<section className='mt-6'>
							<p className='font-semibold text-lg'>No over-pricing</p>
							<summary className='serviceOptions flex gap-5'>
								<p>Cheapest costs</p><li>Competitive prices</li><li> Pay as you go</li>
							</summary>
						</section>
						<section className='mt-6'>
							<p className='font-semibold text-lg'>heading</p>
							<summary className='serviceOptions flex gap-5'>
								<p>1 Option</p><li>2 Option</li><li>3 Option</li>
							</summary>
						</section>
						<section className='mt-6'>
							<p className='font-semibold text-lg'>No return fare</p>
							<summary className='serviceOptions flex gap-5'>
								<p>One-Way cab fare</p><li>No hidden charges</li>
							</summary>
						</section>
					</aside>
				</div>
			</main >
		</>
	)
}

export default TaxiOptions