export const popularLocation = [
	{
		location: "Indore",
		img: "https://res.cloudinary.com/drfni1iqf/image/upload/v1716178791/True%20Taxi/Popular%20Location/indore_debgyh.jpg",
		places: "Ganesh Mandir, Kanch Mandir, Patal pani, Jam gate, Tincha Waterfall, Mandu, Choral Dam, Maheshwar, The Grand Machal, Chokhi Dhani"
	},
	{
		location: "Bhopal",
		img: "https://res.cloudinary.com/drfni1iqf/image/upload/v1716178792/True%20Taxi/Popular%20Location/bhopal_nqk0nb.avif",
		places: "Tribal Museum, Taj ul Masajid, Bharat bhawan, DB City Mall, Upper Lake, Bhimbetka rock shelters, Rani Kamlapati Mahal"
	},
	{
		location: "Delhi",
		img: "https://res.cloudinary.com/drfni1iqf/image/upload/v1716178016/True%20Taxi/Popular%20Location/delhi_njtzdj.png",
		places: "India Gate, Red Fort (Lal Qila), Qutub Minar, Lotus Temple, Akshardham Temple, Humayun's Tomb"
	},
	{
		location: "Agra",
		img: "https://res.cloudinary.com/drfni1iqf/image/upload/v1716178015/True%20Taxi/Popular%20Location/aagra_gzhfyl.webp",
		places: "Gateway of India, Marine Drive, Chhatrapati Shivaji Maharaj Terminus, Siddhivinayak Temple, Haji Ali Dargah, Juhu Beach"
	},
	{
		location: "Ajmer",
		img: "https://res.cloudinary.com/drfni1iqf/image/upload/v1716207828/True%20Taxi/Popular%20Location/ajmer_ptrjvc.jpg",
		places: "khawaja garib nawaj, Taragarh Fort, Anasagar Lake, Mayo College, Buland Darwaza"
	},
	{
		location: "Lucknow",
		img: "https://res.cloudinary.com/drfni1iqf/image/upload/v1716207828/True%20Taxi/Popular%20Location/lakhnow_ookuok.jpg",
		places: "Bada Imambara, Rumi Darwaza, State Museum Lucknow, Dilkusha Kothi, Maa Chandrika Devi Temple, Aminabad, Regional Science City"
	}
];


export const ourServices = {

	heading: "Your Premier Destination for Reliable Transportation Solutions",
	description: "At trueTaxi, we're passionate about providing you with a seamless and stress-free transportation experience. Whether you're commuting to work, exploring a new city, or heading to the airport, we've got you covered with our fleet of meticulously maintained vehicles and dedicated drivers.",

	facility: [
		{
			title: "Reliability",
			description: "Count on us to be there when you need us. With trueTaxi, you can book your ride with confidence, knowing that we prioritize punctuality and professionalism."
		},
		{
			title: "Safety",
			description: "Your safety is our top priority. All of our vehicles undergo regular maintenance checks and adhere to the highest safety standards, so you can relax and enjoy the journey."
		},
		{
			title: "Exceptional Service",
			description: "From the moment you book your ride until you reach your destination, our team is dedicated to providing you with exceptional service. Whether you have special requests or need assistance, we're here to help."
		},
		{
			title: "Affordability",
			description: "Enjoy competitive rates without compromising on quality. At trueTaxi, we believe that reliable transportation should be accessible to everyone, which is why we strive to offer affordable pricing options."
		},
		{
			title: "Convenience",
			description: "Booking your ride has never been easier. Our user-friendly website and mobile app allow you to reserve your taxi with just a few clicks, ensuring a hassle-free experience from start to finish"
		},
		{
			title: "Comfort",
			description: "Experience a comfortable ride in our well-maintained, spacious vehicles with amenities like air conditioning and charging ports."
		}
	],
	services: [
		{ name: "Airport Transfers" },
		{ name: "City Tours" },
		{ name: "Corporate Transportation" },
		{ name: "Special Events" },
		{ name: "And more" },
	],

	experience: "Experience the True Difference With TrueTaxi",
	experienceDisc: "Ready to experience transportation done right? Book your ride with trueTaxi today and discover why we're the preferred choice for travelers and commuters alike. Whether you're traveling solo or with a group, we'll get you to your destination safely and comfortably, every time. Your journey starts here. Book now with trueTaxi!"

}

export const FaQ = [
	{
		question: 'question 1',
		answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus eligendi saepe culpa, id soluta, vel debitis accusamus incidunt hic, iusto facilis officia voluptates! Explicabo quidem id laudantium magni ab voluptatem. Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus eligendi saepe culpa, id soluta, vel debitis accusamus incidunt hic, iusto facilis officia voluptates! Explicabo quidem id laudantium magni ab voluptatem.Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus eligendi saepe culpa, id soluta, vel debitis accusamus incidunt hic, iusto facilis officia voluptates! Explicabo quidem id laudantium magni ab voluptatem.Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus eligendi saepe culpa, id soluta, vel debitis accusamus incidunt hic, iusto facilis officia voluptates! Explicabo quidem id laudantium magni ab voluptatem.'
	},
	{
		question: 'question 2',
		answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus eligendi saepe culpa, id soluta, vel debitis accusamus incidunt hic, iusto facilis officia voluptates! Explicabo quidem id laudantium magni ab voluptatem.'
	},
	{
		question: 'question 3',
		answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus eligendi saepe culpa, id soluta, vel debitis accusamus incidunt hic, iusto facilis officia voluptates! Explicabo quidem id laudantium magni ab voluptatem.'
	},
	{
		question: 'question 4',
		answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus eligendi saepe culpa, id soluta, vel debitis accusamus incidunt hic, iusto facilis officia voluptates! Explicabo quidem id laudantium magni ab voluptatem.'
	},
	{
		question: 'question 5',
		answer: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus eligendi saepe culpa, id soluta, vel debitis accusamus incidunt hic, iusto facilis officia voluptates! Explicabo quidem id laudantium magni ab voluptatem.'
	},
];

