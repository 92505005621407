import React from 'react';
import { popularLocation } from '../../data/data';

function PopularLocation() {
	return (
		<section className='container'>
			<header className=''>
				{/* <h2>Book Cabs for Popular Destinations</h2> */}
				<p className='text-xl font-semibold'>More tourist places....</p>
			</header>
			<div className='mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
				{popularLocation.map((value) => (
					<div className='popularLocation border border-blue-500 bg-[#f6fbff] p-2 rounded-lg flex'>
						<img src={value.img} alt={`${value.location}`} className='w-24 h-28 rounded-lg mr-4 object-cover' />

						<div className='flex-grow'>
							<p className='text-xl font-bold'>{value.location}</p>
							<p className='text-gray-600 leading-5 text-sm'>{value.places}</p>
						</div>
					</div>
				))}
			</div>
			<center className='text-xl font-semibold border border-blue-500 bg-[#f6fbff] rounded-lg mt-4 px-4 py-1'>You plan your own tour, we take you there.</center>
		</section>
	);
}

export default PopularLocation;