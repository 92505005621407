import React from "react";
import "./navbar.css";
import { BiPhoneCall } from "react-icons/bi";
import { GoX } from "react-icons/go";
import { PiUserCircleLight } from "react-icons/pi";
import { Link } from "react-router-dom";

function Navbar() {
	return (
		<>
			<nav className="Nav-section w-full bg-gray-50">
				<div className="container">
					<div className="py-2 flex items-center justify-between">
						<Link to={'/'}>
							<div className="logo h-10 cursor-pointer flex justify-center">
								<img src="./assets/images/logo.png" alt="logo" className="logo-icon" />
								<img
									src="./assets/images/logoName.png"
									alt="logo"
									className="logo-name object-contain"
								/>
							</div>
						</Link>
						<div className="backdrop:number text-lg">
							<div className="heading-number flex items-center bg-themeColor rounded-full pr-3 text-white">
								<p className="flex bg-white rounded-full text-themeColor m-[2px] px-[6px] items-center max-sm:hidden">
									<BiPhoneCall size={25} style={{ fill: '#0377d8' }} /> 24
									<GoX size={25} style={{ fill: '#0377d8' }} />7
								</p>
								&nbsp;
								<a href="tel:+91 9201010334" className="tracking-wide">
									+91 92010 10334
								</a>
							</div>
						</div>
						<div className="navInfo cursor-pointer flex flex-col items-center text-lg px-2">
							<PiUserCircleLight size={30} className="" />
							<p className="text-sm">Account</p>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
}

export default Navbar;
