export const indoreImages = [
	{
		name: "Rajwada",
		img: [
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716472028/True%20Taxi/images/tourist_place/indore/rajwada_1_mdvroo.jpg",
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716471984/True%20Taxi/images/tourist_place/indore/Rajwada2_wqfihm.jpg"
		]
	},
	{
		name: "LalBag Palace",
		img: [
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716470709/True%20Taxi/images/tourist_place/indore/Lalbagh_Palace-1_tghpor.jpg",
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716483443/True%20Taxi/images/tourist_place/indore/Lalbagh_Palace-2_hhbyd3.jpg"
		]
	},
	{
		name: "Ganesh Mandir",
		img: [
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716483512/True%20Taxi/images/tourist_place/indore/ganesh-mandir-1_hkkzwc.jpg",
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716483511/True%20Taxi/images/tourist_place/indore/ganesh-mandir-2_xvmn8p.jpg",
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716483509/True%20Taxi/images/tourist_place/indore/ganesh-Mandir-3_sez5xu.webp"
		]
	},
	{
		name: "phoenix mall",
		img: [
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716483713/True%20Taxi/images/tourist_place/indore/phoenix_ikckim.png",
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716483707/True%20Taxi/images/tourist_place/indore/phoenix1_ftlf6a.jpg",
		]
	},
	{
		name: "Kaanch Mandir",
		img: [
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716483700/True%20Taxi/images/tourist_place/indore/Kaanch_Mandir_ibivjq.jpg",
		]
	},
	{
		name: "Bada ganpati",
		img: [
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716484080/True%20Taxi/images/tourist_place/indore/bada-ganpati_afqgoz.png",
		]
	},
	{
		name: "Annapurna temple",
		img: [
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716484084/True%20Taxi/images/tourist_place/indore/Annapurna_Temple_xdsd6h.jpg",
		]
	},
	{
		name: "Central Museum",
		img: [
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716483698/True%20Taxi/images/tourist_place/indore/Central_Museum_qe2ebf.jpg",
		]
	},
	{
		name: "Chappan",
		img: [
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716483690/True%20Taxi/images/tourist_place/indore/chappan2_ocuyou.jpg",
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716483692/True%20Taxi/images/tourist_place/indore/chappan1_m2u8xr.jpg",
			"https://res.cloudinary.com/drfni1iqf/image/upload/v1716483685/True%20Taxi/images/tourist_place/indore/chappan3_gqgezr.webp",
		]
	},
];