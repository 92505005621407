import React from 'react'
import './TermsAndConditions.css'

function TermsAndConditions() {
	return (
		<>
			<main className='TermsAndConditions'>
				<div className='container'>
					<div className='flex justify-end my-6'>
						<p className='font-semibold text-lg'>Call For Booking Car - <a href="tel:+91 9201010334" className='font-bold tracking-wider text-secondaryColor'>+91 92010 10334</a></p>
					</div>
					<div className='terms-container px-20 text-justify'>

						<h2>Terms And Conditions</h2>
						<p>The use of this website is subject to the following terms of use:</p>
						<ul>
							<li>
								The content of the pages of this website is for your general information and use only. It is subject to change without notice.
							</li>
							<li>
								We receive, collect and store any information you enter on our website or provide us in any other way. In addition, we collect the Internet protocol (IP) address used to connect your computer to the Internet; login; e-mail address; password; computer and connection information and purchase history. We may use software tools to measure and collect session information, including page response times, length of visits to certain pages, page interaction information, and methods used to browse away from the page. We also collect personally identifiable information (including name, email, password, communications); payment details (including credit card information), comments, feedback, product reviews, recommendations, and personal profile.
							</li>
							<li>
								When you conduct a transaction on our website, as part of the process, we collect personal information you give us such as your name, address and email address. Your personal information will be used for the specific reasons stated above only.
							</li>
							<li>
								We collect such Non-personal and Personal Information for the following purposes:
								<ul>
									<li>To provide and operate the Services;</li>
									<li>To provide our Users with ongoing customer assistance and technical support;</li>
									<li>To be able to contact our Visitors and Users with general or personalized service-related notices and promotional messages;</li>
									<li>To create aggregated statistical data and other aggregated and/or inferred Non-personal Information, which we or our business partners may use to provide and improve our respective services;</li>
									<li>To comply with any applicable laws and regulations.</li>
								</ul>
							</li>
							<li>
								Our company is hosted on the Wix.com platform. Wix.com provides us with the online platform that allows us to sell our products and services to you. Your data may be stored through Wix.com’s data storage, databases and the general Wix.com applications. They store your data on secure servers behind a firewall.
							</li>
							<li>
								We may contact you to notify you regarding your account, to troubleshoot problems with your account, to resolve a dispute, to collect fees or monies owed, to poll your opinions through surveys or questionnaires, to send updates about our company, or as otherwise necessary to contact you to enforce our User Agreement, applicable national laws, and any agreement we may have with you. For these purposes we may contact you via email, telephone, text messages, and postal mail.
							</li>
							<li>
								We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
							</li>
						</ul>
						<img src="https://res.cloudinary.com/drfni1iqf/image/upload/v1716902641/True%20Taxi/images/T_C_vilz4l.png" alt="T&C" className='w-60 float-right ml-10 mt-5 mb-10' />
					</div>
				</div>
			</main>
		</>
	)
}

export default TermsAndConditions;