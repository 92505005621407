import React from 'react'
import './OurServices.css'
import { ourServices } from '../../data/data.js'

function OurServices() {
	return (
		<>
			<section className='mt-12 pt-5'>
				<div className='container'>
					<p className='text-[30px] font-semibold'>{ourServices.heading}</p>
					<p>{ourServices.description}</p>
				</div>
				<br />

				<div className=''>
					<p className='container font-bold text-xl mb-4'>Why choose us:</p>
					<div className='container pt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 px-2'>
						{ourServices.facility.map((value, index) => (
							<div className='futureCard mt-2 border-2 border-[#0377d8] rounded-[16px]'>
								<p className='font-semibold text-lg relative px-1 -top-[17px] -right-6 inline bg-white'>{value.title}</p>
								<p className='text-xs px-3 text-gray-700 leading-[18px] relative -top-2'>{value.description}</p>
							</div>
						))}
					</div>
				</div>
				<div className='container my-10 flex bg-teal-300 rounded-[100px] items-center'>
					<div className='px-5 w-[50%]'>
						<h3>{ourServices.experience}</h3>
						<p className=''>{ourServices.experienceDisc}</p>
					</div>
					<img src="https://res.cloudinary.com/drfni1iqf/image/upload/v1716208966/True%20Taxi/images/insidecar_lzbngo.jpg" alt="img" className=' w-[50%]' />
				</div>

				{/* <div className='container'>
					<p className='font-bold text-xl mt-4'>Our Services:</p>
					{ourServices.services.map((value) => (
						<div>
							<p>{value.name}</p>
						</div>
					))}
				</div> */}

			</section>
		</>
	)
}

export default OurServices