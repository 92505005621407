import React, { useState } from 'react';
import './FAQ.css'
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaQ } from '../../data/data'

const AccordionItem = ({ question, answer, isOpen, onClick }) => (
	<div className="accordion-item">
		<div className="accordion-question" onClick={onClick}>
			<p className={`question ${isOpen ? ' active' : ''}`}>{question}</p>
			<div className={`icon ${isOpen ? 'activeIcon' : ''}`}><MdKeyboardArrowRight size={30} className='icons' /></div>
		</div>
		{isOpen && <div className="accordion-answer px-6">{answer}</div>}
	</div>
);

const FAQ = () => {
	const [ openIndexes, setOpenIndexes ] = useState([]);
	const handleClick = (index) => {
		if (openIndexes.includes(index)) {
			setOpenIndexes(openIndexes.filter((i) => i !== index));
		} else {
			setOpenIndexes([ ...openIndexes, index ]);
		}
	};
	// const [ openIndex, setOpenIndex ] = useState(null);
	// const handleClick = (index) => {
	// 	setOpenIndex(index === openIndex ? null : index);
	// };

	return (
		<>
			<div className="container mt-20 accordion">
				<p className='font-medium text-3xl mb-6 underline underline-offset-[10px] '>Frequently Asked Questions (FAQs)</p>
				<div className='accordionItem'>

					{FaQ.map((item, index) => (
						<AccordionItem
							key={index}
							isOpen={openIndexes.includes(index)} //for multiple opens
							// isOpen={index === openIndex}
							question={item.question}
							answer={item.answer}
							onClick={() => handleClick(index)}
						/>
					))}
				</div>
			</div>
		</>
	);
};

export default FAQ;