import './App.css';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './component/ScrollToTop/ScrollToTop';
import Navbar from './component/Navbar/Navbar';
import SearchCar from './component/SearchCar/SearchCar';
import BookCar from './component/BookCar/BookCar';
import PopularLocation from './component/PopularLocation/PopularLocation'
import OurServices from './component/OurServices/OurServices';
import Footer from './component/Footer/Footer';
import CarOptions from './component/CarOptions/CarOptions';
import FAQ from './component/FAQ/FAQ';
import Contact from './component/Contact/Contact';
import TermsAndConditions from './component/Term&Condition/TermsAndConditions';


function App() {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/"
          element={
            <>
              <SearchCar />
              <BookCar />
              <PopularLocation />
              <OurServices />
              <CarOptions />
              <FAQ />
              <Contact />

            </>
          }>
        </Route>
        <Route path="/home"
          element={
            <>
              <SearchCar />
              <BookCar />
              <PopularLocation />
              <OurServices />
              <CarOptions />
              <FAQ />
              <Contact />

            </>
          }>
        </Route>
        <Route path='/TermsAndConditions' element={<TermsAndConditions />}></Route>
        <Route path='/MessageMe' element={<Contact />}></Route>
      </Routes >
      <Footer />
    </>
  );
}

export default App;