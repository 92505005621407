import React, { useState, useEffect } from "react";
import './BookCar.css'
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { indoreImages } from '../../data/indoreImages'
// import { aroundIndore } from '../../data/touristPlace'

function BookCar() {

	const [ activeTab, setActiveTab ] = useState(0);
	const [ activeRoundTripOption, setActiveRoundTripOption ] = useState(null);
	const [ swiperInstance, setSwiperInstance ] = useState(null);

	const aroundIndore = [
		{
			name: "Mandav",
			img: [
				"https://res.cloudinary.com/drfni1iqf/image/upload/v1716485058/True%20Taxi/images/tourist_place/mandav/mandav2_krnkdv.png",
				"https://res.cloudinary.com/drfni1iqf/image/upload/v1716485059/True%20Taxi/images/tourist_place/mandav/mandav_m13mq1.png",
				"https://res.cloudinary.com/drfni1iqf/image/upload/v1716485063/True%20Taxi/images/tourist_place/mandav/mandav3_eqpgsl.png",
			]
		},
		{
			name: "Patal Pani",
			img: [
				"https://res.cloudinary.com/drfni1iqf/image/upload/v1716470709/True%20Taxi/images/tourist_place/indore/Lalbagh_Palace-1_tghpor.jpg",
				"https://res.cloudinary.com/drfni1iqf/image/upload/v1716470708/True%20Taxi/images/tourist_place/indore/Lalbagh_Palace-3_agkwwk.jpg"
			]
		},
		{
			name: "Dhar",
			img: [
				"https://res.cloudinary.com/drfni1iqf/image/upload/v1716178015/True%20Taxi/Popular%20Location/aagra_gzhfyl.webp",
				"https://res.cloudinary.com/drfni1iqf/image/upload/v1716207828/True%20Taxi/Popular%20Location/lakhnow_ookuok.jpg",
				"https://res.cloudinary.com/drfni1iqf/image/upload/v1716207828/True%20Taxi/Popular%20Location/ajmer_ptrjvc.jpg"
			]
		},
		{
			name: "Kala kund",
			img: [
				"https://res.cloudinary.com/drfni1iqf/image/upload/v1716459847/Tausif/temp/360_F_256945038_2OlfEQaNuqLTfWFAogLD9gXVaJ5TXyeP_yvbasw.jpg",
				"https://res.cloudinary.com/drfni1iqf/image/upload/v1716437546/Tausif/temp/img1_cvc7xv.webp",
				"https://res.cloudinary.com/drfni1iqf/image/upload/v1716460125/Tausif/temp/lal-bagh-palace-indore_ra7u4v.jpg",
			]
		},
	];
	const cumulativeIndices = aroundIndore.reduce((acc, group, index) => {
		const lastIndex = acc[ index - 1 ] || 0;
		acc.push(lastIndex + group.img.length);
		return acc;
	}, []);

	const tabData = [
		{
			label: 'Rent car in Indore with TrueTaxi',
			content: (
				<div className="">
					<p className="text-2xl font-semibold">Indore Car Rental with Driver</p>
					<div className="m-auto">
						<div className="flex items-center mt-6 justify-between border border-[#] bg-[#8482ff85] rounded-md py-[6px] pl-3 pr-[6px]">
							<p className="text-2xl font-semibold">Call - &nbsp;</p>
							<div className="flex items-center text-2xl bg-[#ffffff] py-2 px-3 rounded-lg">
								<a href="tel:+91 9201010334" target="_blank" rel="noopener noreferrer" className="flex">
									<img src="https://res.cloudinary.com/drfni1iqf/image/upload/v1716361672/True%20Taxi/images/call_kmfkrq.png" alt="" className="size-8" />&nbsp;
									+91 92010 10334</a>
							</div>
						</div>

						<div className="flex items-center mt-6 justify-between border border-[#] bg-[#71de85] rounded-md py-[6px] pl-3 pr-[6px]">
							<p className="text-2xl font-semibold">Whatsapp - &nbsp;</p>
							<div className="flex items-center text-2xl bg-[#ffffff] py-2 px-3 rounded-lg">
								<a href="https://wa.me/919201010334?text=%2AI want to book car%2A%0A%0ACar Name - %0ABooking Date - %0AFrom - %0ATo -%20" target="_blank" className="flex">
									<img src="https://res.cloudinary.com/drfni1iqf/image/upload/v1716361614/True%20Taxi/images/whatsapp_ywcu37.png" alt="" className="size-8" />&nbsp;
									92010 10334</a></div>
						</div>
					</div>
				</div>
			),
		},
		{
			label: 'Tourist attractions in Indore',
			content: (
				<div className="flex w-[70%] h-full">
					<Swiper
						modules={[ Autoplay, Pagination, Navigation, EffectFade ]}
						spaceBetween={10}
						centeredSlides={true}
						autoplay={{ delay: 2000, disableOnInteraction: false }}
						// pagination={{ clickable: true }}
						navigation={true}
						zoom={true}
						className="mySwiper"
					>
						{indoreImages.map((value, ind) =>
							value.img.map((image, index) => (
								<SwiperSlide key={index}>
									<img src={image} alt={`img-${index}`} className='slider-img' />
									<p key={ind} className="imageName flex justify-center items-center relative -top-8 h-8 w-full tracking-wider">{value.name}</p>
								</SwiperSlide>
							))
						)}
					</Swiper>
				</div >
			),
		},
		{
			label: 'Tourist places to visit around Indore',
			content: (
				<div className="touristPlace-section w-full h-full flex gap-5">
					<ul className="touristPlaceOption">
						{aroundIndore.map((option, index) => (
							<li
								key={index}
								className={activeRoundTripOption === index ? 'active' : ''}
								onClick={() => {
									setActiveRoundTripOption(index);
									const slideToIndex = cumulativeIndices[ index - 1 ] || 0;
									swiperInstance.slideTo(slideToIndex);
								}}
							>
								{option.name}
							</li>
						))}
					</ul>
					<div className="flex w-[70%] ">
						<Swiper
							modules={[ Autoplay, Pagination, Navigation, EffectFade ]}
							spaceBetween={10}
							centeredSlides={true}
							autoplay={{ delay: 2000, disableOnInteraction: false }}
							navigation={true}
							zoom={true}
							onSwiper={setSwiperInstance}
							className="mySwiper"
						>
							{aroundIndore.map((value, groupIndex) =>
								value.img.map((image, imageIndex) => (
									<SwiperSlide key={`${groupIndex}-${imageIndex}`}>
										<img src={image} alt={`img-${groupIndex}-${imageIndex}`} className='slider-img' />
										{/* {imageIndex === 0 && ( */}
										<p className="imageName flex justify-center items-center relative -top-8 h-8 w-full tracking-wider">
											{value.name}
										</p>
										{/* )} */}
									</SwiperSlide>
								))
							)}
						</Swiper>
					</div>
				</div>
			),
		},
		{
			label: 'Arrange a car for long-distance travel',
			content: (
				<div>
					<div>
						<p>Fourth option</p>
					</div>
				</div>
			),
		},
	];

	useEffect(() => {
		if (activeTab === 2) {
			setActiveRoundTripOption(0);
			if (swiperInstance) {
				swiperInstance.slideTo(0);
			}
		}
	}, [ activeTab, swiperInstance ]);

	return (
		<section className="">
			<div className="container mt-24">
				<div className="flex gap-6">
					<div className='w-[40%]'>
						<p className='text-[25px] font-bold'>Book Cabs for Popular Destinations</p>
						<ul className="bookCar">
							{tabData.map((tab, index) => (
								<li key={index}
									className={activeTab === index ? 'active' : ''}
									onClick={() => {
										setActiveTab(index);
										setActiveRoundTripOption(null);
									}}>
									{tab.label}
								</li>
							))}
						</ul>
					</div>
					<div className="flex justify-center items-center w-[60%] h-[350px]">{tabData[ activeTab ].content}</div>
				</div >
			</div>
		</section>
	);
}

export default BookCar;
